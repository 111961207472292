import {
    PageTitle,
    TrackItem,
    TrackEventClick,
    TrackEventTimed,
    TrackOptions,
    VideoSeenString,
} from "@/types";
import { useRoute } from "vue-router";
import { useTrackingStore } from "@/stores/tracking";
import api from "@/api";
import { useMainStore } from "@/stores/main";

export default function useTracking() {
    const store = useTrackingStore();
    const mainStore = useMainStore();
    const route = useRoute();
    const page: PageTitle = route.name as string;

    const addTrackItem = (trackItem: TrackItem) => {
        if (mainStore.hasLoggedIn) {
            api.addTrackItem({
                ...trackItem,
                fe_version: mainStore.versionStringToNumber,
            });
        }
    };

    const startTimingEvent = (event: TrackEventTimed, id: number | string) => {
        store.startTimedEvent(event, id, page);
    };

    const stopTimingEvent = (
        event: TrackEventTimed,
        id: number | string,
        data: TrackOptions
    ) => {
        const timedEvent = store.getTimedEvent(event, id);
        if (timedEvent) {
            const timeSpent = new Date().getTime() - timedEvent.start;
            const trackItem = timedEvent.trackItem;
            trackItem.data = data;
            trackItem.data.timeSpent = timeSpent;
            addTrackItem(trackItem);
            store.removeTimedEvent(timedEvent);
        }
    };

    const clickEvent = (event: TrackEventClick, data: TrackOptions) => {
        const trackItem: TrackItem = {
            event,
            page,
            data,
        };
        addTrackItem(trackItem);
    };

    const enterPage = (data: TrackOptions) => {
        const trackItem: TrackItem = {
            event: "enter-page",
            page,
            data,
        };
        addTrackItem(trackItem);
    };

    const videoSeenEvent = (data: TrackOptions) => {
        const trackItem: TrackItem = {
            event: "video-seen",
            page,
            data,
        };
        addTrackItem(trackItem);
    };

    const ebookReadEvent = (data: TrackOptions) => {
        const trackItem: TrackItem = {
            event: "ebook-read",
            page,
            data,
        };
        addTrackItem(trackItem);
    };

    return {
        startTimingEvent,
        stopTimingEvent,
        clickEvent,
        enterPage,
        videoSeenEvent,
        ebookReadEvent,
    };
}
