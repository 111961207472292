import { RoutePath } from "@/types";

export const otherRoutes: RoutePath[] = [
    {
        path: "/opwaarderen",
        name: "Upgrade",
    },
    {
        path: "/persoonlijke-aanbieding",
        name: "PersonalOffer",
    },
];
